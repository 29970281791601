import cx from "classix";

import Section from "./Section";

const colorVariants = {
  red: { bgColor: "bg-red-500", textColor: "text-red-800" },
  mauve: { bgColor: "bg-mauve-500", textColor: "text-mauve-800" },
  green: { bgColor: "bg-green-500", textColor: "text-green-800" },
  turquoise: { bgColor: "bg-turquoise-500", textColor: "text-turquoise-800" },
};

export const bannerPaddingVariants = {
  defaultWithoutTitle: "py-6 md:py-16",
  defaultWithTitle: "pb-6 pt-5 md:pt-12 md:pb-16",
  homePage: "pb-12 pt-10 md:pb-24 md:pt-20",
};

type Props = {
  title?: string;
  text: string;
  color: keyof typeof colorVariants;
  homePage?: boolean;
};

export default function Banner({
  title,
  text: mainText,
  color,
  homePage = false,
}: Props) {
  const { bgColor, textColor } = colorVariants[color];
  const padding = homePage
    ? bannerPaddingVariants.homePage
    : title
    ? bannerPaddingVariants.defaultWithTitle
    : bannerPaddingVariants.defaultWithoutTitle;

  return (
    <div className={bgColor}>
      <Section className={padding}>
        {title && (
          <h3 className="text-r-2xl mb-5 font-bold text-white">{title}</h3>
        )}
        <p className={cx("text-r-4xl font-normal", textColor)}>{mainText}</p>
      </Section>
    </div>
  );
}
