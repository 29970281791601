import { cx } from "classix";

const gapVariants = {
  24: "gap-x-12 lg:gap-x-24 gap-y-12",
  16: "gap-x-8 lg:gap-x-16 gap-y-8",
  8: "gap-x-4 lg:gap-x-8 gap-y-4",
};

const breakpointVariants = {
  sm: "sm:grid-cols-2",
  md: "md:grid-cols-2",
  lg: "lg:grid-cols-2",
  xl: "xl:grid-cols-2",
};

type Props = {
  children: React.ReactNode;
  className?: string;
  gap?: keyof typeof gapVariants;
  breakpoint?: keyof typeof breakpointVariants;
} & React.HTMLAttributes<HTMLElement>;

export default function GridLayout({
  children,
  className,
  gap = 16,
  breakpoint = "xl",
  ...rest
}: Props) {
  return (
    <article
      className={cx(
        "grid grid-cols-1",
        breakpointVariants[breakpoint],
        gapVariants[gap],
        className
      )}
      {...rest}
    >
      {children}
    </article>
  );
}
