import Title, { TitleProps } from "./Title";

type Props = {
  subtitle?: React.ReactNode;
  primary: React.ReactNode;
  secondary: React.ReactNode;
  primaryColor: keyof typeof primaryVariants;
} & Pick<TitleProps, "textSize">;

const primaryVariants = {
  green: "text-green-300",
  mauve: "text-mauve-500",
};

export default function ColoredTitle({
  primary,
  subtitle,
  secondary,
  primaryColor,
  textSize,
}: Props) {
  return (
    <Title
      subtitle={subtitle}
      title={
        <>
          <span className="leading-none">
            <span className={primaryVariants[primaryColor]}>{primary}</span>
            <br />
            {secondary}
          </span>
        </>
      }
      textSize={textSize}
    />
  );
}
