import Container from "./Container";

export default function Section({
  children,
  ...rest
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>) {
  return (
    <Container tag="section" {...rest}>
      {children}
    </Container>
  );
}
