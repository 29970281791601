import { cx } from "classix";

const colorVariants = {
  default: "text-neutral-700",
  green: "text-green-500",
  turquoise: "text-turquoise-300",
};

const sizeVariants = {
  "7xl": "text-r-7xl",
  "6xl": "text-r-6xl",
};

export type TitleProps = {
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  color?: keyof typeof colorVariants;
  textSize?: keyof typeof sizeVariants;
};

export default function Title({
  subtitle,
  title,
  textSize = "7xl",
  color = "default",
}: TitleProps) {
  const titleTag = (
    <h2
      className={cx(
        "max-w-[13ch] font-bold",
        colorVariants[color],
        sizeVariants[textSize]
      )}
    >
      {title}
    </h2>
  );

  return subtitle ? (
    <div>
      <h4 className="text-r-lg mb-2 font-semibold leading-1.2 text-neutral-600">
        {subtitle}
      </h4>
      {titleTag}
    </div>
  ) : (
    titleTag
  );
}
