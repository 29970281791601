import { useState, useRef } from "react";
import { cx } from "classix";

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  arrowClassName?: string;
  darkMode?: boolean;
};

export default function ScrollableContainer({
  children,
  className,
  style,
  arrowClassName,
  darkMode = false,
}: Props) {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const speed = 1;

  function handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
    setIsDragging(true);
    setStartX(e.pageX - (containerRef.current?.offsetLeft || 0));
    setScrollLeft(containerRef.current?.scrollLeft || 0);
  }

  function handleMouseUp() {
    setIsDragging(false);
  }

  function handleMouseMove(e: React.MouseEvent<HTMLDivElement>) {
    if (!isDragging || !containerRef.current) return;
    e.preventDefault();
    const x = e.pageX - (containerRef.current?.offsetLeft || 0);
    const walk = (x - startX) * speed;
    containerRef.current.scrollLeft = scrollLeft - walk;
  }

  function handleArrowClick(left: boolean) {
    const start = containerRef.current?.scrollLeft || 0;
    if (start === 0 && left) {
      return;
    }
    const target = start + (left ? -1 : 1) * 300;
    const duration = 400;

    const animateScroll = (timestamp: number) => {
      if (!containerRef.current) {
        return;
      }

      const progress = Math.min(1, (timestamp - startTime) / duration);
      containerRef.current.scrollLeft = start + progress * (target - start);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    const startTime = performance.now();
    requestAnimationFrame(animateScroll);
  }

  return (
    <div className="flex flex-col w-full">
      <div
        ref={containerRef}
        className={cx("hide-scroll-bar overflow-x-scroll", className)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={style}
      >
        {children}
      </div>
      <div
        className={cx(
          "w-16 flex justify-between mt-4 self-end",
          arrowClassName
        )}
      >
        <button onClick={() => handleArrowClick(true)}>
          <LeftArrow dark={darkMode} />
        </button>
        <button onClick={() => handleArrowClick(false)}>
          <RightArrow dark={darkMode} />
        </button>
      </div>
    </div>
  );
}

function LeftArrow({ dark, className }: { dark: boolean; className?: string }) {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      stroke={dark ? "white" : "#282828"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.0576C6.47715 22.0576 2 17.5805 2 12.0576C2 6.53477 6.47715 2.05762 12 2.05762C17.5228 2.05762 22 6.53477 22 12.0576C22 17.5805 17.5228 22.0576 12 22.0576Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.0576L8 12.0576L12 8.05762"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16 12.0576H8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function RightArrow({ dark }: { dark: boolean }) {
  return <LeftArrow className="transform rotate-180" dark={dark} />;
}
