import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ScrollableContainer from "components/ScrollableContainer";
import { TypeHomePageFields } from "contentful-generated";
import { Document } from "@contentful/rich-text-types";
import BicoloredTitle from "components/BicoloredTitle";
import { getContentfulImageUrl } from "utils/utils";
import GridLayout from "components/GridLayout";
import { useTranslation } from "react-i18next";
import { getHomePage } from "repos/homePage";
import { GetStaticPropsContext } from "next";
import Section from "components/Section";
import Banner from "components/Banner";
import Header from "components/Header";
import Layout from "components/Layout";
import Image from "next/image";
import Link from "next/link";

import womensLaughingImage from "public/images/services/womens-laughing.png";
import headLookingUpImage from "public/images/services/head-looking-up.png";
import womenLaughingImage from "public/images/services/women-laughing.png";
import mensWorkingImage from "public/images/services/mens-working.png";
import { i18n } from "lib/i18n";

export async function getStaticProps({ locale = "en" }: GetStaticPropsContext) {
  const homePage = await getHomePage({ locale });
  return { props: homePage };
}

export default function HomePage({
  mainTitle,
  banner,
  coloredSubtitle,
  coloredPrimaryTitle,
  coloredSecondaryTitle,
  firstParagraph,
  firstList,
  secondSectionTitle,
  secondParagraph,
  clientCards,
}: TypeHomePageFields) {
  const { t } = useTranslation();

  const services = [
    {
      title: t("learning_experiences_description"),
      subtitle: t("learning_experiences"),
      link: `/${i18n.language}/experiences`,
      image: womensLaughingImage,
      alt: "",
      disabled: true,
    },
    {
      title: t("custom_programs_description"),
      subtitle: t("custom_programs"),
      link: `/${i18n.language}/programs`,
      image: womenLaughingImage,
      alt: "",
    },
    {
      title: t("business_coaching_description"),
      subtitle: t("business_coaching"),
      link: `/${i18n.language}/coaching`,
      image: headLookingUpImage,
      alt: "",
    },
    {
      title: t("eLearning_solutions_description"),
      subtitle: t("eLearning_solutions"),
      link: `/${i18n.language}/develop`,
      image: mensWorkingImage,
      alt: "",
      disabled: true,
    },
  ];

  //https://github.com/contentful/rich-text/issues/123#issuecomment-1104422063
  const mainTitleRendered = documentToReactComponents(mainTitle as Document);

  return (
    <Layout>
      <Header
        className={`bg-[url('/images/persons/nada-kibrite.png')]`}
        title={mainTitleRendered}
        homePage
      />
      <main>
        <Banner
          title={banner.fields.title}
          text={banner.fields.text}
          color="red"
          homePage
        />
        <div className="bg-gray">
          <Section className="pb-28 pt-12 md:pt-24">
            <GridLayout className="pb-8" breakpoint="md">
              <BicoloredTitle
                subtitle={coloredSubtitle}
                primary={coloredPrimaryTitle}
                secondary={coloredSecondaryTitle}
                primaryColor="green"
              />
              <article>
                <p className="text-r-xl 5 mb-14 font-semibold leading-1.2 text-neutral-600">
                  {firstParagraph}
                </p>
                <article className="mx-10 mt-10 grid w-2/3 grid-cols-1 gap-x-6 gap-y-12 text-base font-bold leading-1.2 text-green-400 md:mx-0 md:w-full md:grid-cols-2 md:text-sm xl:text-base">
                  {firstList.map((item) => (
                    <p className="max-w-[25ch]" key={item}>
                      {item}
                    </p>
                  ))}
                </article>
              </article>
            </GridLayout>
            <h3 className="text-r-xl text-neutral-600 leading-1.2 mt-8 font-bold mb-4">
              {t("our_services")}
            </h3>
            <article className="grid grid-cols-1 md2:grid-cols-2 justify-center gap-4 xl:gap-8">
              {services.map(
                ({ title, subtitle, link, alt, image, disabled }) => (
                  <article
                    key={title}
                    className="flex flex-col h-full rounded-lg bg-white"
                  >
                    <Image
                      className={"mx-auto object-contain w-full rounded-t-lg"}
                      src={image}
                      alt={alt}
                    />
                    <div className="flex flex-col items-start p-6">
                      <h4 className="text-r-2xl font-normal leading-1.1 mb-1">
                        {subtitle}
                      </h4>
                      <h3 className="text-r-3xl font-semibold leading-1.2 mb-6 max-w-[22ch]">
                        {title}
                      </h3>
                      {!disabled ? (
                        <Link
                          href={link}
                          className="text-r-base font-semibold leading-1.2 text-blue hover:underline"
                        >
                          {t("learn_more")}
                        </Link>
                      ) : (
                        <p className="text-r-base font-semibold leading-1.2 text-gray-600">
                          {t("learn_more")}
                        </p>
                      )}
                    </div>
                  </article>
                )
              )}
            </article>
          </Section>
        </div>
        <Section className="pb-28 pt-20 md:pt-16">
          <GridLayout
            breakpoint="sm"
            className="mb-10 grid items-baseline lg:mb-20"
          >
            <h2 className="text-r-5xl max-w-[20ch] font-bold">
              {secondSectionTitle}
            </h2>
            <p className="text-r-lg max-w-[45ch] text-left font-normal">
              {secondParagraph}
            </p>
          </GridLayout>
          <article className="flex w-full justify-center">
            <ScrollableContainer className="my-10 flex flex-row flex-nowrap gap-4 md:gap-8">
              {clientCards.map((card) => (
                <div className="inline-block" key={card.fields.clientName}>
                  <article
                    className="flex h-96 w-[250px] flex-col items-center overflow-hidden rounded-lg"
                    style={{ backgroundColor: card.fields.color }}
                  >
                    <Image
                      className="rounded-t-lg"
                      src={getContentfulImageUrl(
                        card.fields.coverImage.fields.file.url
                      )}
                      alt={`${card.fields.clientName} banner`}
                      width={250}
                      height={184}
                    />
                    {/* eslint-disable-next-line @next/next/no-img-element*/}
                    <img
                      className="my-auto h-[auto] max-w-[228px]"
                      src={getContentfulImageUrl(
                        card.fields.logo.fields.file.url
                      )}
                      alt={`${card.fields.clientName} logo`}
                    />
                  </article>
                </div>
              ))}
            </ScrollableContainer>
          </article>
        </Section>
      </main>
    </Layout>
  );
}
